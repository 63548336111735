import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { eRoles } from '../enums/roles.enum';
import { injectSupabase } from '../functions/inject-supabase.function';
import { Base } from '../interfaces/base.interface';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApi<T extends Partial<Base>> {
  private authService = inject(AuthService);
  protected http = inject(HttpClient);

  supabase = injectSupabase();

  constructor(
    protected tableName: string,
    protected apiUrl: string
  ) {}

  get userId() {
    return this.authService.currentUser.id;
  }
  get userEmail() {
    return this.authService.currentUser.email;
  }
  get userRoles() {
    return this.authService.currentUser.roles;
  }
  get isAdmin() {
    return this.userRoles?.includes(eRoles.ADMIN);
  }
  get isSuperAdmin() {
    return this.userRoles?.includes(eRoles.SUPER_ADMIN);
  }

  getById(id: string) {
    return this.supabase.from(this.tableName).select('*').eq('id', id).eq('active', true).single();
  }

  select(select = '*', options: { isAscending?: boolean; active?: boolean } = { isAscending: true, active: true }) {
    if (options.active === undefined) options.active = true;
    if (options.isAscending === undefined) options.isAscending = true;

    return this.supabase.from(this.tableName).select(select, { count: 'exact' }).eq('active', options.active).order('created_at', { ascending: options.isAscending });
  }

  insertOrUpdate(payload: T) {
    return payload.id ? this.update(payload).eq('id', payload.id) : this.insert(payload);
  }

  insert(payload: Omit<T, keyof Base>) {
    return this.supabase.from(this.tableName).insert([{ ...payload, created_by: this.authService.currentUser.id, active: true }]);
  }

  insertMany(payload: Omit<T, keyof Base>[]) {
    return this.supabase.from(this.tableName).insert(
      payload.map(item => {
        return { ...item, created_by: this.authService.currentUser.id, active: true };
      })
    );
  }

  update(payload: Partial<T>) {
    payload.modified_by = this.authService.currentUser.id;
    payload.modified_at = new Date();

    return this.supabase.from(this.tableName).update([payload]);
  }

  updateById(id: string, payload: Partial<T>, columnName = 'id') {
    return this.supabase.from(this.tableName).update([payload]).eq(columnName, id);
  }

  deleteById(id: string, columnName = 'id') {
    return this.supabase.from(this.tableName).delete().eq(columnName, id);
  }

  inactiveById(id: string, columnName = 'id') {
    return this.supabase.from(this.tableName).update({ active: false }).eq(columnName, id);
  }
}
