import { Database } from 'types/supabase';

import { Inject, Injectable } from '@angular/core';
import { iEnvironment } from '@ministrary/shared/interfaces/environment.interface';
import { createClient, SupabaseClient } from '@supabase/supabase-js';

@Injectable({
  providedIn: 'root'
})
export class SupabaseService {
  private supabaseUrl: string;
  private supabaseKey: string;

  supabase: SupabaseClient;

  constructor(@Inject('environment') environment: iEnvironment) {
    this.supabaseUrl = environment.supabaseUrl;
    this.supabaseKey = environment.supabaseKey;

    this.supabase = createClient<Database>(this.supabaseUrl, this.supabaseKey);
  }
}
