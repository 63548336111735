import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { eMinistryRoles } from '@ministrary/shared/enums/ministry-roles.enum';
import { eMinistryUserStatus } from '@ministrary/shared/enums/ministry-user-status.enum';
import { Session } from '@supabase/supabase-js';

import { eRoles } from '../../enums/roles.enum';
import { eUserStatus } from '../../enums/user-status.enum';
import { injectSupabase } from '../../functions/inject-supabase.function';
import { iUser } from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private router = inject(Router);
  private supabase = injectSupabase();

  session$ = new BehaviorSubject<Session | undefined>(undefined);

  private currentUserSubject = new BehaviorSubject<iUser>({} as iUser);
  currentUser$ = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  get currentUser(): iUser {
    return this.currentUserSubject.value;
  }
  get user() {
    return this.currentUser.user_metadata;
  }

  isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  get isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.value;
  }

  get isOffline() {
    return !window.navigator.onLine;
  }

  get isTestUser() {
    return this.currentUser.email?.includes('samuel+1@ministrary.com');
  }

  ministryUserRoles: { [key: string]: boolean };

  async load() {
    const { data, error } = await this.supabase.auth.getSession();
    if (!data || error) await this.refreshSession();

    if (!data.session?.user) return this.purgeAuth();
    this.isAuthenticatedSubject.next(true);

    this.session$.next(data.session);
    await this.setUser(data.session.user);

    if (this.currentUser.status === eUserStatus.pending) return this.router.navigate(['/complete-registration']);
  }

  async setUser(user: iUser) {
    const userData = await this.loadUserData(user.id);
    if (!userData) return;

    if (!userData.avatar_url && user.user_metadata['avatar_url']) {
      const avatar_url = user.user_metadata['avatar_url'];
      userData.avatar_url = user.user_metadata['avatar_url'];
      await this.supabase.from('users').update({ avatar_url }).eq('id', user.id);
    }

    this.currentUserSubject.next(Object.assign(user, userData));
    this.isAuthenticatedSubject.next(true);

    this.updateMinistryUserRoles();
  }

  async updateUser(data: Partial<iUser>, id?: string) {
    const { data: user, error } = await this.supabase
      .from('users')
      .update(data)
      .eq('id', id ?? this.currentUser.id)
      .select()
      .single();
    if (error) throw error;
    this.currentUserSubject.next(Object.assign(this.currentUser, user));
  }

  hasRole(roles?: eRoles[]) {
    const user = this.currentUser;
    return user.roles && roles?.some(role => user.roles?.includes(role));
  }

  hasMinistryRole(roles?: eMinistryRoles[]) {
    return roles?.some(role => this.ministryUserRoles[role]);
  }

  updateMinistryUserRoles() {
    const ministryUser = this.currentUser.ministry_user;
    if (!ministryUser) return;

    const isLeader = ministryUser.some(ministryUser => ministryUser.is_leader);
    const isScheduler = ministryUser.some(ministryUser => ministryUser.is_scheduler);
    const isKidsVolunteer = ministryUser.some(ministryUser => ministryUser?.ministry?.is_child_ministry);
    const isKidsLeader = ministryUser.some(ministryUser => ministryUser.is_leader && ministryUser?.ministry?.is_child_ministry);
    const canControlLiveEvents = ministryUser.some(ministryUser => ministryUser.can_control_live_events);

    this.ministryUserRoles = {
      leader: isLeader,
      scheduler: isScheduler,
      volunteer: ministryUser.length > 0,
      kids_volunteer: isKidsVolunteer,
      kids_leader: isKidsLeader,
      control_live_events: canControlLiveEvents
    };
  }

  signOut() {
    this.supabase.auth.signOut().then(() => {
      this.purgeAuth();
      this.router.navigateByUrl('auth');
    });
  }

  purgeAuth() {
    this.currentUserSubject.next({} as iUser);
    this.isAuthenticatedSubject.next(false);
  }

  inviteUser(email: string) {
    return this.supabase.auth.admin.inviteUserByEmail(email);
  }

  updateStatus(userId: string, status: eUserStatus) {
    return this.supabase.from('users').update({ status }).eq('id', userId);
  }

  refreshSession() {
    return this.supabase.auth.refreshSession();
  }

  private async loadUserData(userId: string): Promise<iUser> {
    const { data: user } = await this.supabase
      .from('users')
      .select('*, ministry_user!ministry_user_users_id_fkey(*, ministry(is_child_ministry))')
      .match({ id: userId, active: true, 'ministry_user.active': true, 'ministry_user.status': eMinistryUserStatus.APPROVED })
      .single();
    return user as unknown as iUser;
  }
}
